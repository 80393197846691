import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal, { Prompt, ModalHeader, ModalFooter } from '@ingka/modal';
import informationCircle from '@ingka/ssr-icon/paths/information-circle';
import SSRIcon from '@ingka/ssr-icon';
import {
  clearFormAction,
  setFormFieldValueAction
} from 'Redux/forms/formsActions';
import { t } from 'Utils/localization/i18next';
import {
  updateProfile,
  updateProfileSettings
} from 'Redux/profile/profileThunks';
import analytics from 'Utils/analytics/analyticsWrapper';
import { LOADING } from 'Redux/userInterface/userInterfaceConstants';
import { Button, Text } from 'Common';
import { trimAllObjPropertiesSkipPasswords } from 'Utils/object/objectUtils';
import styles from './CommunicationPreferences.scss';

class UnsubscribeSectionContainer extends React.PureComponent {
  state = { modalOpen: false };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      showSuccessMessage: false,
      isConfirming: false
    };
    this.myRef = React.createRef();
  }

  subscribeUnsubscribeAll = () => {
    const subscription = {};
    if (this.props.allowEmail !== undefined) {
      subscription.allowEmail = this.props.allowAllConsent;
    }
    if (this.props.allowSMS !== undefined) {
      subscription.allowSMS = this.props.allowAllConsent;
    }
    if (this.props.allowDirectMail !== undefined) {
      subscription.allowDirectMail = this.props.allowAllConsent;
    }
    if (this.props.allowSharingToSocialMedia !== undefined) {
      subscription.allowSharingToSocialMedia = this.props.allowAllConsent;
    }
    return subscription;
  };

  onConfirm = () => {
    this.setState({
      isConfirming: true
    });
  };

  onCancel = () => {
    this.setState({
      isConfirming: false
    });
  };

  onSubmit = async data => {
    const consentData = this.subscribeUnsubscribeAll();
    const newProfile = { ...this.props.profile, ...data, ...consentData };
    const consentSuccess = await this.props.updateProfileSettings(
      trimAllObjPropertiesSkipPasswords(newProfile),
      `${this.props.profile.profileType}-dashboard-communication-subscribe-unsubscribe-all`
    );
    if (consentSuccess) {
      this.setState({
        isEditing: false,
        showSuccessMessage: true,
        isConfirming: false
      });
    }
    this.scrollToCenter();

    if (this.props.allowAllConsent) {
      this.props.setFormFieldValueAction(
        'family-dashboard-sms-communication-preferences',
        'allowSMS',
        true
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-email-communication-preferences',
        'allowEmail',
        true
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-direct-mail-communication-preferences',
        'allowDirectMail',
        true
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-social-media-communication-preferences',
        'allowSharingToSocialMedia',
        true
      );
    } else {
      this.props.setFormFieldValueAction(
        'family-dashboard-sms-communication-preferences',
        'allowSMS',
        false
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-email-communication-preferences',
        'allowEmail',
        false
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-direct-mail-communication-preferences',
        'allowDirectMail',
        false
      );
      this.props.setFormFieldValueAction(
        'family-dashboard-social-media-communication-preferences',
        'allowSharingToSocialMedia',
        false
      );
    }
  };

  scrollToCenter = () => {
    if (this.myRef) {
      this.myRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  setRef = node => {
    if (node) {
      this.myRef = node;
    }
  };

  render() {
    const modalTitleId = 'mymodaltitle';
    return (
      <React.Fragment>
        <div ref={this.setRef} className={styles['section-read-unsubscribe']}>
          <SSRIcon
            prefix={CONFIG.APP.STYLES_PREFIX}
            paths={informationCircle}
            className={styles['section-read-unsubscribe-icon']}
          />
          <Text
            colour="grey-900"
            className={styles['section-read-unsubscribe-text']}
          >
            {this.props.allowAllConsent
              ? t('dashboard.communications.subscribe-section.subscribe')
              : t('dashboard.communications.unsubscribe-section.unsubscribe')}
          </Text>
          <div className={styles['section-read-unsubscribe-box']}>
            <Button
              className={styles['section-read-unsubscribe-box-button']}
              onClick={() => {
                this.setState({ modalOpen: true });
                analytics.userProfileFormUpdate({
                  status: 'started',
                  form: `${this.props.profile.profileType}-dashboard-communication-subscribe-unsubscribe-all`
                });
                this.onConfirm();
              }}
              text={
                this.props.allowAllConsent
                  ? t(
                      'dashboard.communications.subscribe-section.subscribe-all'
                    )
                  : t(
                      'dashboard.communications.unsubscribe-section.unsubscribe-all'
                    )
              }
              prefix={CONFIG.APP.STYLES_PREFIX}
              marginTop="s"
              small={true}
              type="secondary"
              disabled={
                this.props.allowAllConsent
                  ? this.props.allowEmail &&
                    this.props.allowDirectMail &&
                    this.props.allowSMS &&
                    this.props.allowSharingToSocialMedia
                  : !this.props.allowEmail &&
                    !this.props.allowDirectMail &&
                    !this.props.allowSMS &&
                    !this.props.allowSharingToSocialMedia
              }
            />
            <Modal
              visible={this.state.modalOpen}
              escapable={true}
              handleCloseBtn={() => {
                analytics.userProfileFormUpdate({
                  status: 'canceled',
                  form: `${this.props.profile.profileType}-dashboard-communication-subscribe-unsubscribe-all`
                });
                this.setState({ modalOpen: false });
              }}
              prefix={CONFIG.APP.STYLES_PREFIX}
            >
              <Prompt
                aria-labelledby={modalTitleId}
                title={
                  this.props.allowAllConsent
                    ? t(
                        'dashboard.communications.subscribe-section.subscribe-all'
                      )
                    : t(
                        'dashboard.communications.unsubscribe-section.unsubscribe-all'
                      )
                }
                titleId={modalTitleId}
                header={<ModalHeader ariaCloseTxt="Close prompt" />}
                prefix={CONFIG.APP.STYLES_PREFIX}
                footer={
                  <ModalFooter prefix={CONFIG.APP.STYLES_PREFIX}>
                    <Button
                      autoWidth
                      marginTop="s"
                      marginRight="s"
                      small={true}
                      text={t(
                        'dashboard.communications.unsubscribe-section.confirm'
                      )}
                      type="primary"
                      onClick={() => {
                        this.setState({ modalOpen: false });
                        this.onSubmit();
                      }}
                      prefix={CONFIG.APP.STYLES_PREFIX}
                    />
                    <Button
                      autoWidth
                      marginTop="s"
                      small={true}
                      text={t(
                        'dashboard.communications.unsubscribe-section.cancel'
                      )}
                      type="secondary"
                      onClick={() => {
                        analytics.userProfileFormUpdate({
                          status: 'canceled',
                          form: `${this.props.profile.profileType}-dashboard-communication-subscribe-unsubscribe-all`
                        });
                        this.setState({ modalOpen: false });
                        this.onCancel();
                      }}
                      prefix={CONFIG.APP.STYLES_PREFIX}
                    />
                  </ModalFooter>
                }
              >
                <p>
                  {this.props.allowAllConsent
                    ? t(
                        'dashboard.communications.subscribe-section.description-modal'
                      )
                    : t(
                        'dashboard.communications.unsubscribe-section.description-modal'
                      )}
                </p>
              </Prompt>
            </Modal>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

UnsubscribeSectionContainer.propTypes = {
  editForm: PropTypes.string,
  profile: PropTypes.object,
  // From Redux/Thunks
  updateProfile: PropTypes.func.isRequired,
  updateProfileSettings: PropTypes.func.isRequired,
  clearFormAction: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  allowEmail: PropTypes.bool,
  allowSMS: PropTypes.bool,
  allowDirectMail: PropTypes.bool,
  allowSharingToSocialMedia: PropTypes.bool,
  setFormFieldValueAction: PropTypes.func.isRequired,
  allowAllConsent: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    profile: state.profile,
    isLoading: state.userInterface.loading[LOADING.PROFILE_UPDATE],
    allowEmail: state.profile.allowEmail,
    allowSMS: state.profile.allowSMS,
    allowDirectMail: state.profile.allowDirectMail,
    allowSharingToSocialMedia: state.profile.allowSharingToSocialMedia
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateProfile,
      updateProfileSettings,
      clearFormAction,
      setFormFieldValueAction
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnsubscribeSectionContainer);
